import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { createSafeContext, useSafeContext } from '@utilities/context';

type DrawerPlacement = 'top' | 'right' | 'bottom' | 'left';

interface DrawerGeneralProps {
  placement?: DrawerPlacement;
  isOpen?: boolean;
  onClose?: () => void;
}

const useDrawerController = ({ isOpen, onClose }: DrawerGeneralProps) => {
  const [titleId, setTitleId] = useState<string>();
  const [descriptionId, setDescriptionId] = useState<string>();
  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);
  const data = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open) {
        close();
      }
    },
  });
  const { context } = data;
  const dismiss = useDismiss(context, { outsidePress: false });
  const role = useRole(context);
  const interactions = useInteractions([dismiss, role]);

  return useMemo(
    () => ({
      isOpen,
      close,
      ...interactions,
      ...data,
      titleId,
      descriptionId,
      setTitleId,
      setDescriptionId,
    }),
    [isOpen, close, interactions, data, titleId, descriptionId],
  );
};

type ContextType = ReturnType<typeof useDrawerController>;

const DrawerContext = createSafeContext<ContextType>();

type DrawerProps = ComponentPropsWithoutRef<'div'> & DrawerGeneralProps;

export const useDrawer = () => useSafeContext(DrawerContext);

const Drawer = ({
  isOpen,
  onClose,
  className,
  placement = 'left',
  children,
  ...props
}: DrawerProps) => {
  const value = useDrawerController({ isOpen, onClose });
  const { context, isOpen: open, descriptionId, close, titleId, refs, getFloatingProps } = value;

  return (
    <DrawerContext.Provider value={value}>
      <FloatingPortal>
        {open && (
          <FloatingOverlay
            className={classNames(
              'flex bg-neutral-600/80 !overflow-hidden z-popover',
              ['top', 'bottom'].includes(placement) && 'flex-col',
              {
                'flex-row-reverse': placement === 'right',
              },
            )}
            lockScroll
          >
            <FloatingFocusManager context={context}>
              <div
                className={classNames('drawer', className)}
                ref={refs.setFloating}
                aria-labelledby={titleId}
                aria-describedby={descriptionId}
                {...getFloatingProps(props)}
              >
                {children}
              </div>
            </FloatingFocusManager>
            <div onClick={close} className="flex-1 min-w-[5rem]" />
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </DrawerContext.Provider>
  );
};

export default Drawer;
